<template>
  <v-container fluid>
    <validation-observer ref="observer">
      <v-row>
        <v-col>
          <validation-observer ref="observer">
            <v-card>
              <v-toolbar
                dense
                elevation="0"
                color="primary"
                class="secondary--text"
                dark
              >
                <v-toolbar-title>LEAVE</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form @submit.prevent ref="form1">
                  <v-row justify="center" align="center">
                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Leave"
                        rules="required|alpha_spaces"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Leave"
                          v-model="leave"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Division"
                        rules="required"
                      >
                        <v-select
                          @change="changeStaffDivision"
                          outlined
                          class="rounded-0"
                          label="Staff Division"
                          :items="getters_staff_divisions"
                          v-model="division"
                          item-text="division"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Staff Category"
                        rules="required"
                      >
                        <v-select
                          outlined
                          :disabled="categoryBasket.length <= 0"
                          class="rounded-0"
                          label="Staff Category"
                          :items="categoryBasket"
                          v-model="category"
                          item-text="category"
                          item-value="id"
                          :error-messages="errors"
                          hide-details="auto"
                        ></v-select>
                      </validation-provider>
                    </v-col>

                    <v-col cols="12" lg="3">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Days"
                        rules="required"
                      >
                        <v-text-field
                          outlined
                          class="rounded-0"
                          label="Days"
                          v-model.number="day"
                          :error-messages="errors"
                          hide-details="auto"
                          :suffix="typeDays"
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col sm="5" md="5" lg="10">
                      <v-card flat>
                        <v-card-text>
                          <span
                            v-for="(color, i) in ex4"
                            :key="`${i}-key`"
                            @click="chooseColor(i, color)"
                          >
                            <v-btn
                              class="mx-1"
                              elevation="0"
                              fab
                              x-small
                              :color="color"
                            >
                              <v-icon v-if="current === i"
                                >mdi-check-bold</v-icon
                              >
                            </v-btn>
                          </span>
                        </v-card-text>

                        <span></span>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </validation-observer>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-btn
            color="success"
            :loading="loading"
            @click="sendData"
            class="font-weight-bold"
          >
            save
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="getters_staff_leave"
          class="elevation-1"
          item-key="id"
          :loading="loading"
          hide-default-footer
        >
          <template #item.color="{ value }">
            <v-icon :color="value"> mdi-circle </v-icon>
          </template>
          <template #item.days="{ item }">
            {{ `${item.days} ${item.type_days}` }}
          </template>
          <template #item.actions="{ item }">
            <v-menu
              offset-y
              open-on-hover
              transition="slide-x-transition"
              bottom
              right
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  outlined
                  color="grey darken-1"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list
                dark
                color="primary darken-2"
                min-width="250"
                tile
                class="py-0"
              >
                <v-list-item-group>
                  <v-list-item link @click="editPositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Edit</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-list-item link @click="deletePositionBtn(item)">
                    <v-list-item-content>
                      <v-list-item-title>Delete</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-icon>
                      <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!--Edit Position -->
    <v-navigation-drawer
      v-model="showComponent"
      fixed
      :permanent="showComponent"
      right
      class="shadow"
      clipped
      width="30%"
    >
      <v-toolbar class="mt-15 secondary--text" color="primary">
        <v-btn
          left
          class="ma-2 secondary--text"
          @click="showComponent = !showComponent"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-toolbar-title>STAFF POSITIONS</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn class="ma-2 secondary--text" icon @click="showComponent = false">
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </v-toolbar>

      <component :is="componentCurrent" v-if="showComponent" />
    </v-navigation-drawer>

    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import { provide, reactive, ref, toRefs, watch } from "vue";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationProvider, ValidationObserver } from "vee-validate";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import {
    required,
    numeric,
    alpha,
    alpha_spaces,
    min_value,
  } from "vee-validate/dist/rules";
  import EditLeaveSetup from "@/components/Leave/EditLeaveSetup";
  import DeleteLeaveSetup from "@/components/Leave/DeleteLeaveSetup";

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return parseInt(value) + parseInt(target) !== 100;
    },
    message: "Both Fields Should Add Up To 100%",
  });

  extend("required", {
    ...required,
    message: "{_field_} is required",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} is required",
  });

  extend("alpha", {
    ...alpha,
  });

  extend("numeric", {
    ...numeric,
  });

  extend("min_value", {
    ...min_value,
  });

  export default {
    components: {
      Response,
      ValidationProvider,
      ValidationObserver,
      EditLeaveSetup,
      DeleteLeaveSetup,
    },
    setup() {
      const { getStaffDivision, getLeave, getStaffPosition } = useActions([
        "getStaffDivision",
        "getLeave",
        "getStaffPosition",
      ]);

      const {
        getters_staff_divisions,
        getters_staff_leave,
        getters_staff_positions,
      } = useGetters([
        "getters_staff_divisions",
        "getters_staff_leave",
        "getters_staff_positions",
      ]);

      Promise.all([getStaffDivision(), getLeave(), getStaffPosition()]).then(
        () => (loading.value = false)
      );

      const leaveForm = reactive({
        showComponent: false,
        componentCurrent: null,
        data: {},
        leave: null,
        category: null,
        division: null,
        actionResponse: false,
        valid: false,
        observer: null,
        overlay: false,
        color: null,
        msgBody: null,
        msgIcon: null,
        msgHeader: null,
        loading: true,
        form1: null,
        current: null,
        typeDays: "working days",
        day: null,
        ex4: ["error", "primary", "secondary", "success", "info", "warning"],
        headers: [
          { text: "", value: "color", width: "1%" },
          { text: "LEAVE", value: "leave" },
          { text: "STAFF DIVISION", value: "staff_division.division" },
          { text: "STAFF CATEGORY", value: "staff_category.category" },
          { text: "DAYS", value: "days" },
          { text: "ACTIONS", value: "actions" },
        ],
        categoryBasket: [],
      });

      const {
        categoryBasket,
        showComponent,
        componentCurrent,
        form1,
        data,
        leave,
        division,
        category,
        observer,
        color,
        msgBody,
        msgIcon,
        actionResponse,
        msgHeader,
        loading,
        current,
        typeDays,
        day,
      } = toRefs(leaveForm);

      const leaveColor = ref(null);

      const { saveLeave } = useActions(["saveLeave"]);

      const chooseColor = (i, color) => {
        current.value = i;
        leaveColor.value = color;
      };

      const sendData = async () => {
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          if (result && leaveColor.value) {
            loading.value = true;

            saveLeave({
              leave: leave.value,
              staff_category_id: category.value,
              staff_division_id: division.value,
              day: day.value,
              type: typeDays.value,
              color: leaveColor.value,
            })
              .then(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "success";
                msgBody.value = "Grade Is Successfully Saved.";
                msgIcon.value = "mdi-check-circle";
                msgHeader.value = "Saved";
              })
              .catch(() => {
                observer.value.reset();
                actionResponse.value = true;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgHeader.value = "Error";
                msgIcon.value = "mdi-close-circle";
              })
              .finally(() => {
                loading.value = false;
                form1.value.reset();
              });
          } else {
            actionResponse.value = true;
            msgHeader.value = "Error";
            msgIcon.value = "mdi-close-circle";
            msgBody.value = "Select At Least One Color";
            color.value = "error";
          }
        });
      };

      watch([category, division], ([x, y]) => {
        if (x && y) {
          let categories = categoryBasket.value;
          let divisions = getters_staff_divisions.value;
          let selectedCat = categories.find((item) => item.id == x);
          let selectedDiv = divisions.find((item) => item.id == y);

          typeDays.value =
            selectedCat.category.toLowerCase() == "senior member" &&
            selectedDiv.division.toLowerCase() == "non-academic"
              ? "consecutive days"
              : "working days";
        }
      });

      provide("color", color);

      const editPositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "EditLeaveSetup";
        showComponent.value = true;
      };

      const deletePositionBtn = (item) => {
        data.value = item;
        componentCurrent.value = "DeleteLeaveSetup";
        showComponent.value = true;
      };

      provide("leave", data);

      const changeStaffDivision = (id) => {
        let basket = getters_staff_divisions.value.find((item) => item.id == id);
        categoryBasket.value = basket.category;
      };

      return {
        ...toRefs(leaveForm),
        getters_staff_divisions,
        getters_staff_leave,
        getters_staff_positions,
        sendData,
        editPositionBtn,
        deletePositionBtn,
        chooseColor,
        changeStaffDivision,
      };
    },
  };
</script>
